import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { LinkedIn, Twitter, GitHub } from '../components/icons';
import Layout from '../components/layout';
import Container from '../components/container';

const Index = ({ data }) => {
  const images = {};
  data.allFile.edges.forEach((edge) => {
    const image = edge.node.childrenImageSharp[0];
    images[edge.node.name] = image.gatsbyImageData;
  });
  return (
    <Layout title='About - Cotera'>
      <Container>
        <div className='bg-white min-h-screen'>
          <div className='mx-auto pt-12 pb-24 px-4 max-w-7xl sm:px-6 lg:px-8 '>
            <div className='space-y-12'>
              <div className='mx-auto space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl '>
                <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pt-4'>
                  Team
                </h2>
                <div className='pt-8 w-full flex flex-row justify-around'>
                  {people.map((person) => (
                    <div className='p-4'>
                      <Person
                        key={person.name}
                        image={images[person.imageKey]}
                        {...person}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Index;

const people = [
  {
    name: 'Tom Firth',
    role: 'Founder',
    imageKey: 'tom-firth',
    twitterUrl: 'https://twitter.com/tdfirth',
    linkedInUrl: 'https://www.linkedin.com/in/tom-firth-7963977b/',
    githubUrl: 'https://github.com/tdfirth',
  },
  {
    name: 'Ibby Syed',
    role: 'Founder',
    imageKey: 'ibby-syed',
    twitterUrl: 'https://twitter.com/StatueofIBBertY',
    linkedInUrl: 'https://www.linkedin.com/in/ibby/',
    githubUrl: 'https://github.com/ibbysyed',
  },
  {
    name: 'TJ Broodryk',
    role: 'Founding Engineer',
    imageKey: 'tj-broodryk',
    twitterUrl: 'https://twitter.com/coterahq',
    linkedInUrl: 'https://www.linkedin.com/in/tjbroodryk/',
    githubUrl: 'https://github.com/tjbroodryk',
  },
  {
    name: 'Grant Powell',
    role: 'Founding Engineer',
    imageKey: 'grant-powell',
    twitterUrl: 'https://twitter.com/coterahq',
    linkedInUrl: 'https://www.linkedin.com/in/grant-powell/',
    githubUrl: 'https://github.com/GrantJamesPowell',
  },
  {
    name: 'Charis Yoon',
    role: 'Growth Intern',
    imageKey: 'charis-yoon',
    twitterUrl: 'https://twitter.com/coterahq',
    linkedInUrl: 'https://www.linkedin.com/in/charis-yoon',
    githubUrl: 'https://github.com/coterahq',
  },
];

function Person(props) {
  return (
    <div key={props.name}>
      <div className='flex flex-col content-center place-items-center space-y-4'>
        <div className='aspect-w-3 aspect-h-2'>
          <GatsbyImage
            image={props.image}
            alt={props.name}
            className={`${props.styles} mx-auto rounded-3xl h-48 w-48 border border-opacity-70 border-indigo-500 shadow-indigo-200 shadow-lg`}
          />
        </div>

        <div className='space-y-2 justify-center'>
          <div className='text-sm leading-6'>
            <h3 className='text-gray-900 text-center font-semibold'>
              {props.name}
            </h3>
            <p className='text-gray-500 text-center'>{props.role}</p>
          </div>
          <ul className='flex place-items-center space-x-5 justify-center'>
            <SocialMediaLink link={props.twitterUrl}>
              <Twitter />
            </SocialMediaLink>
            <SocialMediaLink link={props.linkedInUrl}>
              <LinkedIn />
            </SocialMediaLink>
            {'githubUrl' in props ? (
              <SocialMediaLink link={props.githubUrl}>
                <GitHub />
              </SocialMediaLink>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
}

function SocialMediaLink(props) {
  return (
    <li>
      <a
        href={props.link}
        className='text-indigo-500 hover:text-indigo-600'
        target='_blank'
        rel='noopener noreferrer'
      >
        {props.children}
      </a>
    </li>
  );
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "people" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(layout: CONSTRAINED, height: 400, width: 400)
          }
          name
        }
      }
    }
    teamBanner: file(name: { eq: "about-us" }) {
      childrenImageSharp {
        gatsbyImageData
      }
    }
  }
`;
